import storage from "src/services/storage";
import { TOKEN } from "src/helpers/constant";
import router from "src/router";
import { Auth } from "aws-amplify";
import { PureAbility, AbilityBuilder } from "@casl/ability";
import { reloadWindow, setMenuByPlanFunction } from "src/helpers/functions";
import { logLogoutHistoryApi } from "src/services/api";
import { store } from "../index";

const state = {
  userInfo: null,
};

const getters = {};

const mutations = {
  updateUser(state, item) {
    state.userInfo = item;

    if (state.userInfo) {
      const { plan_functions: planFunctions } = state.userInfo;
      if (planFunctions) {
        // Update Plan Functions for logged user
        const { can, rules } = new AbilityBuilder(PureAbility);
        planFunctions.forEach((planFunction) => {
          can(planFunction.code);
        });
        this._vm.$ability.update(rules);
      }
    }
    setMenuByPlanFunction();
  },
};

const actions = {
  async login(context, term) {
    storage.set(TOKEN, term);
  },
  async logout(context) {
    if (storage.get(TOKEN)) {
      store.commit("app/setLoading", [await logLogoutHistoryApi()]);
    }
    storage.remove(TOKEN);
    router.push({ name: "Login" });
    Auth.signOut();
    await context.commit("updateUser", null);
    setTimeout(() => {
      reloadWindow();
    }, 1000);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
