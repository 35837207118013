import creditCardType from "credit-card-type";

export const TOKEN = "token";

export const LOCALES = {
  ENGLISH: "en",
  JAPANESE: "ja",
};

export const LOCALES_TO_LANGUAGE = {
  [LOCALES.ENGLISH]: LOCALES.ENGLISH,
  [LOCALES.JAPANESE]: "jp",
};

export const LOCALES_MAP = {
  [LOCALES.ENGLISH]: ["firstname", "lastname"],
  [LOCALES.JAPANESE]: ["lastname", "firstname"],
};

export const COUNTRY_CODE = {
  ENGLISH: 1,
  JAPAN: 81,
  VIETNAM: 84,
};

export const PAYMENT_METHOD = {
  SUBSCRIPTION_CARD: 1,
  CASH_TRANSFER: 2,
};

export const PAYMENT_TYPE = {
  MONTHLY: 1,
  YEARLY: 2,
};

export const CONVERSATION_STATUS_TYPE = {
  ACTIVE: 1,
  DELETED: 2,
};

export const STATUS_USER = {
  ACTIVE: 1,
  INACTIVE: 2,
  BLOCK: 3,
  INVITATION: 4,
};

export const CONVERSATION_STATUS = {
  ACTIVE: 1,
  DELETE: 2,
};

export const USER_STATUS_TYPE = {
  ACTIVE: 1,
  INACTIVE: 2,
  BLOCK: 3,
};

export const GENDER = {
  MALE: 0,
  FEMALE: 1,
  NON_BINARY: 2,
};

export const CONTACT_TYPE = {
  BLOCK: 1,
  FOLLOW: 2,
  CONTACT: 3,
  CONNECT: 4,
  SCAN_CARD: 5,
  QR_CODE: 6,
  REQUEST_CONNECT: 7,
  IMPORT_CONTACT: 8,
};

export const USER_STATUS = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 2,
  },
  {
    label: "Block",
    value: 3,
  },
];

export const FILE_SIZE_MAX = 5 * 1024 * 1024;

export const EMPLOYEE_SIZES = [
  { id: 1, min: 0, max: 4 },
  { id: 2, min: 5, max: 9 },
  { id: 3, min: 10, max: 19 },
  { id: 4, min: 20, max: 29 },
  { id: 5, min: 30, max: 49 },
  { id: 6, min: 50, max: 99 },
  { id: 7, min: 100, max: 299 },
  { id: 8, min: 300, max: 999 },
  { id: 9, min: 1000, max: 4999 },
  { id: 10, min: 5000 },
];

export const INVOICE_TYPE = {
  ONE_TIME_PAYMENT: 0,
  SUBSCRIPTION: 1,
};

export const INVOICE_STATUS = {
  DRAFT: 0,
  OPEN: 1,
  PAID: 2,
  CANCEL: 3,
  UNPAID: 4,
  CARD_ERROR: 5,
};

export const REQUEST_CANCEL_STATUS = {
  NONE: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const CARD_TYPES = {
  AMERICAN_EXPRESS: "amex",
  DINERS_CLUB: "diners",
  DISCOVER: "discover",
  JCB: "jcb",
  MASTERCARD: "mastercard",
  UNIONPAY: "unionpay",
  VISA: "visa",
};

export const CARD_NAMES = {
  [CARD_TYPES.AMERICAN_EXPRESS]: "American Express",
  [CARD_TYPES.DINERS_CLUB]: "Diners Club",
  [CARD_TYPES.DISCOVER]: "Discover",
  [CARD_TYPES.JCB]: "JCB",
  [CARD_TYPES.MASTERCARD]: "Mastercard",
  [CARD_TYPES.UNIONPAY]: "UnionPay",
  [CARD_TYPES.VISA]: "Visa",
  unknown: "Unknown",
};

export const CARD_TYPES_LIBRARY_MAPPING = {
  [creditCardType.types.VISA]: CARD_TYPES.VISA,
  [creditCardType.types.MASTERCARD]: CARD_TYPES.MASTERCARD,
  [creditCardType.types.AMERICAN_EXPRESS]: CARD_TYPES.AMERICAN_EXPRESS,
  [creditCardType.types.DINERS_CLUB]: CARD_TYPES.DINERS_CLUB,
  [creditCardType.types.DISCOVER]: CARD_TYPES.DISCOVER,
  [creditCardType.types.JCB]: CARD_TYPES.JCB,
  [creditCardType.types.UNIONPAY]: CARD_TYPES.UNIONPAY,
};

export const PLAN_SUBTYPES = {
  BASE: "base",
  LICENSE: "license",
};

export const PRODUCT_NAMES = {
  PLAN: "plan",
  STORAGE: "storage",
  SWITCH_TO_YEARLY: "switch_to_yearly",
};

export const TIME = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "24:00",
  "24:30",
  "25:00",
  "25:30",
  "26:00",
  "26:30",
  "27:00",
  "27:30",
  "28:00",
  "28:30",
  "29:00",
  "29:30",
  "30:00",
];

export const CHANNEL_PRIVACY = [
  { value: 5, key: "only_me" },
  { value: 2, key: "follower" },
  { value: 1, key: "anyone" },
];

export const PLAN_FUNCTION = {
  UNLIMITED_CARD_SCAN: 1,
  UNLIMITED_GROUP_CHAT: 2,
  PR_CHANNEL: 3,
  FILE_TRANSMISSION: 4,
  IP_DEVICE_RESTRICTIONS: 5,
  GROUP_CHAT_EXPORT: 6,
  USER_CONTACT_EXPORT: 7,
};

export const PLAN_FUNCTIONS = [
  PLAN_FUNCTION.UNLIMITED_CARD_SCAN,
  PLAN_FUNCTION.UNLIMITED_GROUP_CHAT,
  PLAN_FUNCTION.PR_CHANNEL,
  PLAN_FUNCTION.FILE_TRANSMISSION,
  PLAN_FUNCTION.IP_DEVICE_RESTRICTIONS,
  PLAN_FUNCTION.GROUP_CHAT_EXPORT,
  PLAN_FUNCTION.USER_CONTACT_EXPORT,
];

export const POSTCODE_SLICE_INDEX = 3;

export const STRING_SLICE_INDEX = 30;

export const ADMIN_PERMISSION = {
  VIEW_INVOICE: 1,
  VIEWER: 2,
  SUB_ADMIN: 3,
  ADMIN: 9999,
};

export const ORGANIZATION_PERMISSION = {
  VIEW_LIST: 1,
  VIEW_DETAIL: 2,
  EDIT: 3,
  DELETE: 4,
};
