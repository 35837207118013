const state = {
  initial: null,
  selectedPlan: null,
  plan: null
}

const getters = {}

const mutations = {
  setSelectedPlan(state, item) {
    state.selectedPlan = item
  },
  updateInitial: (state, data) => {
    state.initial = data
  },
  updatePlan: (state, data) => {
    state.plan = data
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
