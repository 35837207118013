import storage from 'src/services/storage'
import i18n from 'src/services/i18n'
import vue from 'src/main'

import {
  LOCALES,
  PAYMENT_METHOD,
  PAYMENT_TYPE,
  PLAN_SUBTYPES,
  PRODUCT_NAMES,
  REQUEST_CANCEL_STATUS,
  INVOICE_STATUS,
  COUNTRY_CODE, PLAN_FUNCTION
} from 'src/helpers/constant'
import { formatDate as formatDateUtil } from 'element-ui/src/utils/date-util'
import { dateFormats } from '@/constants/config'
import { loadStripe } from '@stripe/stripe-js'
import { store } from 'src/store'
import { localeOptions } from 'src/constants/config'
import { MENU } from 'src/data/menu'
import router from 'src/router'

export function getCurrentLocale() {
  return storage.get('currentLanguage') || LOCALES.ENGLISH
}

export function formatCardNumber(value) {
  const v = inputOnlyNumber(value)
  const matches = v.match(/\d{4,16}/g)
  const match = matches && matches[0] || ''
  const parts = []
  for (let i = 0; i < match.length; i += 4) {
    parts.push(match.substring(i, i + 4))
  }
  return parts.length ? parts.join(' ') : v
}

export function formatExpire(value) {
  let v = inputOnlyNumber(value)
  if (value.length === 1) {
    const first = v.charAt(0)
    v = first.replace(/[^0-1]/gi, '')
  }
  const matches = v.match(/\d{2,4}/g)
  const match = matches && matches[0] || ''
  const parts = []
  for (let i = 0; i < match.length; i += 2) {
    parts.push(match.substring(i, i + 2))
  }
  return parts.length ? parts.join('/') : v
}

export function inputOnlyNumber(value) {
  return value.replace(/[^0-9]/gi, '')
}

export function findObj(arr, keyMap, valueMap, keyReturn) {
  if (arr && arr.length > 0) {
    const result = arr.find(val => val[keyMap] === valueMap)
    return result ? result[keyReturn] : ''
  }
  return ''
}

export function formatIndustries(data) {
  const currentLanguage = storage.get('currentLanguage') || LOCALES.ENGLISH
  const result = []
  data.forEach(item => {
    result.push({
      _id: item._id,
      code: item.code,
      name: item.name[currentLanguage]
    })
  })
  return result
}

export function formatEmployeeSizes(data) {
  const result = []
  data.forEach(item => {
    result.push({
      id: item.id,
      text: `${item.min} ~ ${item.max || i18n.t('or_more')}`
    })
  })
  return result
}

export function formatEmployeeSize(data) {
  return {
    id: data.id,
    text: `${data.min} ~ ${data.max || i18n.t('or_more')}`
  }
}

export function formatUrl(url) {
  if (!url) return ''
  return url.includes('https://') || url.includes('http://') ? url : 'https://' + url
}

export function formatPaymentType(value) {
  const text = value === PAYMENT_TYPE.MONTHLY ? i18n.t('payment_screen.monthly') : i18n.t('payment_screen.yearly')
  return {
    value: value,
    text: text
  }
}

export function formatPaymentMethod(value) {
  let text = null
  switch (value) {
    case PAYMENT_METHOD.SUBSCRIPTION_CARD:
      text = i18n.t('payment_screen.subscription_card')
      break
    case PAYMENT_METHOD.CASH_TRANSFER:
      text = i18n.t('payment_screen.cash_transfer')
      break
  }
  return {
    value: value,
    text: text
  }
}

export function formatPaymentPeriod(value) {
  let text = null
  switch (value) {
    // case PAYMENT_METHOD.SPOT_CARD:
    //   text = i18n.t('payment_screen.spot_card')
    //   break
    case PAYMENT_TYPE.YEARLY:
      text = i18n.t('plan_screen.yearly')
      break
    case PAYMENT_TYPE.MONTHLY:
      text = i18n.t('plan_screen.monthly')
      break
  }
  return text
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

export function roundCurrency(currency, price) {
  const power = Math.pow(10, new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).resolvedOptions().maximumFractionDigits)

  return Math.round(price * power) / power
}

export function formatPriceNumber(currency, price, withSymbol = true) {
  if (withSymbol) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(price)
  }

  const currencyFractionDigits = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).resolvedOptions().maximumFractionDigits

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: currencyFractionDigits,
    maximumFractionDigits: currencyFractionDigits
  }).format(price)
}

export function formatProductName(name, type, startDate) {
  switch (type) {
    case PRODUCT_NAMES.SWITCH_TO_YEARLY:
      return i18n.t('plan_change.product_name_switch_to_yearly', { date: startDate })
    default:
      return name
  }
}

export function formatPlanSubtype(subType) {
  switch (subType) {
    case PLAN_SUBTYPES.BASE:
      return i18n.t('plan_change.plan_type_base')
    case PLAN_SUBTYPES.LICENSE:
      return i18n.t('plan_change.plan_type_license')
  }
}

export function formatInvoiceProductName(name, type, subtype = null, unixStartDate = null, unixEndDate = null) {
  const startDate = formatDate(new Date(unixStartDate))
  const endDate = formatDate(new Date(unixEndDate))
  let productName = formatProductName(name, type, startDate)
  if (subtype) {
    productName += ' (' + formatPlanSubtype(subtype) + ')'
  }
  if (unixStartDate && unixEndDate) {
    productName += ' (' + startDate + ' - ' + endDate + ')'
  }

  return productName
}

export function formatDate(date) {
  const lang = localStorage.getItem('currentLanguage') || LOCALES.ENGLISH
  return formatDateUtil(date, dateFormats[lang]) || date
}

export function formatMonthYear(month, year) {
  const lang = localStorage.getItem('currentLanguage') || LOCALES.ENGLISH
  if (lang === LOCALES.ENGLISH) {
    let textMonth = ''
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    months.forEach((item, index) => {
      if (index + 1 === month) {
        textMonth = item
      }
    })
    return `${textMonth}, ${year}`
  }
  if (lang === LOCALES.JAPANESE) {
    return `${year}年${month}月`
  }
}

export function formatDateType() {
  const lang = storage.get('currentLanguage') || LOCALES.ENGLISH
  if (lang === LOCALES.ENGLISH) {
    return 'MM/dd/yyyy'
  }
  if (lang === LOCALES.JAPANESE) {
    return 'yyyy/MM/dd'
  }
}

export function formatTax(value) {
  return Math.round(value * 1000) / 10
}

export function scrollToTop() {
  window.scrollTo(0, 0)
}

export function convertPrice(price, currency) {
  return Number(price).toLocaleString('en-US', {
    style: 'currency',
    currency: currency.toUpperCase()
  })
}

export function formatStatusInvoice(status, paymentMethod, requestCancelStatus) {
  let textStatus = ''
  let textClass = ''

  switch (status) {
    case INVOICE_STATUS.OPEN:
      textStatus = i18n.t('status_invoice.open')
      textClass = 'badge-light-warning'
      break
    case INVOICE_STATUS.PAID:
      textStatus = i18n.t('status_invoice.paid')
      if (paymentMethod === PAYMENT_METHOD.SUBSCRIPTION_CARD) {
        textStatus += ` - ${i18n.t('payment_screen.subscription_card')}`
      }
      if (paymentMethod === PAYMENT_METHOD.CASH_TRANSFER) {
        textStatus += ` - ${i18n.t('payment_screen.cash_transfer')}`
      }
      textClass = 'badge-light-success'
      break
    case INVOICE_STATUS.CANCEL:
      textStatus = i18n.t('status_invoice.cancel')
      textClass = 'badge-light-info'
      break
    case INVOICE_STATUS.UNPAID:
      textStatus = i18n.t('status_invoice.unpaid')
      textClass = 'badge-light-danger'
      break
    case INVOICE_STATUS.CARD_ERROR:
      textStatus = i18n.t('status_invoice.card_error')
      textClass = 'badge-light-danger'
      break
  }

  if (requestCancelStatus === REQUEST_CANCEL_STATUS.PENDING) {
    textStatus = `${i18n.t('status_invoice.request_cancel')} (${textStatus})`
    textClass = 'badge-light-danger'
  }
  return {
    status: textStatus,
    class: textClass
  }
}

export function validateUsers(users) {
  if (!String(users).match(/^[0-9\\-]/)) {
    return i18n.t('validate.users_number')
  }
  if (users < 1) {
    return i18n.t('validate.users_min_1')
  }
  return null
}

export function createStripe(options = {}) {
  return loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY, {
    locale: getCurrentLocale(),
    ...options
  })
}

export function createAutoResolvePromise(timeout = 5000) {
  let outputResolve
  const promise = new Promise(resole => {
    outputResolve = () => resole()
    setTimeout(() => resole(), timeout)
  })

  return {
    promise,
    resolve: outputResolve
  }
}

export function getLanguageByCountry(country) {
  switch (parseInt(country.code)) {
    case COUNTRY_CODE.JAPAN:
      return localeOptions[1]
    default:
      return localeOptions[0]
  }
}

export function getCompanyName(user) {
  if (user.organization && user.organization.name) {
    return user.organization.name
  } else if (user.business && user.business.company) {
    return user.business.company
  }
  return ''
}

export function setMenuByPlanFunction() {
  const can = vue.$can
  const menu = MENU.filter(item => {
    if (
      (item.title === i18n.t('pr_channel') && !can(PLAN_FUNCTION.PR_CHANNEL)) ||
      (item.title === i18n.t('side_bar.security_management') && !can(PLAN_FUNCTION.FILE_TRANSMISSION) && !can(PLAN_FUNCTION.IP_DEVICE_RESTRICTIONS))
    ) {
      return false
    }

    return true
  })
  store.commit('menu/setMenu', menu)
}

export function checkPermissionPlanFunction(functions) {
  let allow = false
  functions.forEach(item => {
    if (router.app.$can(item)) {
      allow = true
    }
  })
  return allow
}

export function reloadWindow() {
  window.location.href = window.location.href.split('#')[0]
}
