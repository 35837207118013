<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{ loderhide: !isLoading }">
      <div class="loader-box">
        <div class="loader-19" />
      </div>
    </div>
    <!-- Loader ends-->

    <notifications group="notify" :ignore-duplicates="true" />

    <!--<Main/>-->
    <router-view />
  </div>
</template>

<script>
import { store } from 'src/store'
import Amplify from 'aws-amplify'
import i18n from 'src/services/i18n'
Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_REGION,
    identityPoolRegion: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'CUSTOM_AUTH'
  }
})

export default {
  name: 'App',
  metaInfo() {
    return {
      meta: [
        {
          httpEquiv: 'Content-Language',
          content: localStorage.getItem('currentLanguage')
        }
      ],
      title: 'CMS | Empaty B2C'
    }
  },
  data() {
    return {}
  },
  computed: {
    isLoading() {
      return store.state.app.loading
    },
    isExceedStorage() {
      return store.state.storage.exceedAlertThreshold
    }
  },
  async mounted() {
    await this.$store.commit('initialiseStore')
  },
  watch: {
    isExceedStorage(value) {
      if (value) {
        this.$swal({
          type: 'warning',
          text: i18n.t('exceed_storage'),
          showCancelButton: false
        }).then(() => { })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}

.loader-wrapper {
  opacity: 0.5;
  z-index: 2000;
}

.loader-box .loader-19 {
  border-left-color: var(--theme-deafult);
  border-right-color: var(--theme-deafult);
}
</style>
