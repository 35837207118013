<script>
export default {
  props: {
    title: {
      type: String,
      default: "home",
    },
    main: {
      type: String,
      default: "",
    },
    main_link: {
      type: String,
      default: "/home",
    },
  },
};
</script>
<template>
  <div>
    <div class="container-fluid">
      <div class="page-title">
        <div class="row">
          <div class="col-6 d-flex">
            <h3>{{ title }}</h3>
          </div>
          <div class="col-6">
            <ol class="breadcrumb pull-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">
                  <feather type="home" />
                </router-link>
              </li>
              <li v-if="main" class="breadcrumb-item">
                <router-link :to="main_link">{{ main }}</router-link>
              </li>
              <li v-if="title" class="breadcrumb-item active">
                {{ title }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.page-wrapper {
  .page-body-wrapper {
    .page-title {
      .breadcrumb {
        .breadcrumb-item {
          p,
          a {
            color: #000;
          }
          a {
            &:hover {
              color: #be185a;
            }
          }
        }
      }
    }
  }
}
</style>
