import { TOKEN } from "src/helpers/constant";
import storage from "src/services/storage";
import { store } from "src/store";
import {
  getCurrentPlanDetail,
  getCurrentTimeTrial,
  getUserLoggedApi,
  loginAsOrganizationAdminApi
} from "src/services/api";
import router from "@/router/index";
import { checkPermissionPlanFunction } from "src/helpers/functions";

export function auth(to, from, next) {
  if (document.referrer === process.env.VUE_APP_SYS_SITE) {
    storage.set(TOKEN, "sys_token");
  }
  const token = storage.get("token");
  token ? next() : next({ name: "Login" });
}

export async function guest(to, from, next) {
  if (document.referrer === process.env.VUE_APP_SYS_SITE) {
    storage.get(TOKEN) ? next({ name: "Home" }) : next();
  }
}

export async function trialPeriod(to, from, next) {
  if (!store.state.plans.initial) {
    store.commit(
      "plans/updateInitial",
      getCurrentPlanDetail().then(async (res) => {
        if (res.status !== 200) {
          await store.commit("plans/updateInitial", null);
          return;
        }
        const { plan } = res.data;
        if (!plan.is_default) {
          await store.commit("plans/updatePlan", plan);
        } else {
          await store.commit("plans/updatePlan", plan);
          await store.commit("trial/setIsTrial", true);
          await store.commit("trial/setNextRequest", false);
          await store.commit("trial/setTrialExpiration", 0);
          await store.commit("trial/setEndTimeTrial", 0);
        }

        if (plan && plan.is_trial) {
          await store.commit("trial/setIsTrial", plan.is_trial);
          await store.commit("trial/setTrialId", plan.trial_id);

          const resTrial = await getCurrentTimeTrial(plan.trial_id);
          if (resTrial.status === 200) {
            const trial = resTrial.data;
            const endTimeTrial = trial.endTimeTrial;
            const trialExpiration = trial.trialExpiration;

            await store.commit("trial/setTrialExpiration", trialExpiration);
            await store.commit("trial/setEndTimeTrial", endTimeTrial);
            await store.commit("trial/setNextRequest", trialExpiration > 0);
          }
        }
      })
    );
  }

  Promise.resolve(store.state.plans.initial).then(() => {
    if (
      !store.state.plans.plan ||
      store.state.plans.plan.is_default ||
      store.state.plans.plan.is_expired
    ) {
      if (to.name !== "PlanChange") {
        next({ name: "PlanChange" });
      }
    } else if (
      store.state.plans.plan &&
      (!store.state.trial.isTrial || to.name === "PlanChange")
    ) {
      next();
    } else {
      store.state.trial.nextRequest ? next() : next({ name: "Home" });
    }
  });
}

export async function checkFunction(to, from, next) {
  if (!store.state.user.userInfo) {
    const res = await getUserLoggedApi();
    if (!storage.get('organization_id')) {
      storage.set('organization_id', res.data.organization_id);
    }
    if (res.status === 200) {
      store.commit("user/updateUser", res.data);
    }
  }
  if (!router.app.$can || !checkPermissionPlanFunction(to.meta.functions)) {
    next(from);
    return false;
  }
  next();
}

export async function organizationTrial(to, from, next) {
  if (!store.state.plans.initial) {
    store.commit(
      "plans/updateInitial",
      getCurrentPlanDetail().then(async (res) => {
        if (res.status !== 200) {
          await store.commit("plans/updateInitial", null);
          return;
        }
        await store.commit("plans/updatePlan", res.data.plan);
        const is_trial = res.data.plan.is_trial;
        store.commit("trial/setIsTrial", is_trial);

        if (res.data.plan.is_default) {
          await store.commit("trial/setIsTrial", true);
          await store.commit("trial/setNextRequest", false);
          await store.commit("trial/setTrialExpiration", 0);
          await store.commit("trial/setEndTimeTrial", 0);
        }
      })
    );
  }
  Promise.resolve(store.state.plans.initial).then(() => {
    if (
      !store.state.plans.plan ||
      store.state.plans.plan.is_default ||
      store.state.plans.plan.is_expired
    ) {
      if (to.name !== "PlanChange") {
        next({ name: "PlanChange" });
      }
    } else if (store.state.trial.isTrial) {
      next({ name: "Home" });
    } else {
      next();
    }
  });
}
