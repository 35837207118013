/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import layout from "src/store/modules/layout";
import menu from "src/store/modules/menu";
import user from "src/store/modules/user";
import app from "src/store/modules/app";
import plans from "src/store/modules/plans";
import trial from "src/store/modules/trial";
import storage from "src/store/modules/storage";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    cart: [],
    cartTotal: 0,
    numberItem: 0,
    totalTax: 0,
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      window.location.reload();
    },
    changeCountry(state, payload) {
      localStorage.setItem("currentCountry", payload);
    },
    async initialiseStore(state) {
      localStorage.removeItem('cart');
      localStorage.removeItem('cartTotal');
      localStorage.removeItem('totalTax');

      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      }
      if (localStorage.getItem('cartTotal')) {
        state.cartTotal = parseFloat(localStorage.getItem('cartTotal'))
      }
      if (localStorage.getItem('totalTax')) {
        state.totalTax = parseFloat(localStorage.getItem('totalTax'))
      }
      return true;
    },
    addRemoveCart(state, payload) {
      //add or remove item
      payload.toAdd ?
        state.cart.unshift(payload.product) :
        state.cart = state.cart.filter(function (obj) {
          return obj._id !== payload.product._id;
        });

      //calculating the total
      state.cartTotal = state.cart.reduce((accumulator, object) => {
        if (object.type_price == "month") {
          return parseFloat(accumulator) + parseFloat(object.monthly_price);
        } else {
          return parseFloat(accumulator) + parseFloat(object.annually_price);
        }
        // return parseFloat(accumulator) + parseFloat(object.price_excl_tax * object.quantity);
      }, 0);

      //calculating the total tax
      state.totalTax = state.cart.reduce((accumulator, object) => {
        if (object.type_price == "month") {
          return parseFloat(accumulator) + parseFloat(object.monthly_price * object.organization.tax);
        } else {
          return parseFloat(accumulator) + parseFloat(object.annually_price * object.organization.tax);
        }
        // return parseFloat(accumulator) + parseFloat(object.price_excl_tax * object.quantity * object.organization.tax);
      }, 0);

      state.numberItem = state.cart.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.quantity);
      }, 0);

      //saving in web storage
      localStorage.setItem('cartTotal', JSON.stringify(state.cartTotal));
      localStorage.setItem('cart', JSON.stringify(state.cart));
      localStorage.setItem('totalTax', JSON.stringify(state.totalTax));
    },
    updateCart(state, payload) {
      //update quantity 
      state.cart.find(o => o._id === payload.product._id).quantity = payload.product.quantity;

      //calculating the total
      state.cartTotal = state.cart.reduce((accumulator, object) => {
        if (object.type_price == "month") {
          return parseFloat(accumulator) + parseFloat(object.monthly_price);
        } else {
          return parseFloat(accumulator) + parseFloat(object.annually_price);
        }
      }, 0);

      state.totalTax = state.cart.reduce((accumulator, object) => {
        if (object.type_price == "month") {
          return parseFloat(accumulator) + parseFloat(object.monthly_price * object.organization.tax);
        } else {
          return parseFloat(accumulator) + parseFloat(object.annually_price * object.organization.tax);
        }
      }, 0);

      state.numberItem = state.cart.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.quantity);
      }, 0);


      //saving in web storage
      localStorage.setItem('cartTotal', JSON.stringify(state.cartTotal));
      localStorage.setItem('totalTax', JSON.stringify(state.totalTax));
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    clearCart(state) {
      state.cart = [];

      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    setQuantity(state, quantity) {
      state.numberItem = quantity;
    },

  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
    setCountry({ commit }, payload) {
      commit("changeCountry", payload);
    },
  },
  modules: {
    layout,
    menu,
    user,
    app,
    plans,
    trial,
    storage,
  },
});
