const state = {
  isTrial: false,
  trialId: null,
  endTime: 0,
  trialExpiration: 0,
  nextRequest: false
}

const getters = {}

const mutations = {
  setIsTrial(state, item) {
    state.isTrial = item
  },
  setTrialId(state, item) {
    state.trialId = item
  },
  setEndTimeTrial(state, item) {
    state.endTime = item
  },
  setTrialExpiration(state, item) {
    state.trialExpiration = item
  },
  setNextRequest(state, item) {
    state.nextRequest = item
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
