import { LOCALES } from '@/helpers/constant'

export const defaultLocale = LOCALES.ENGLISH
export const localeOptions = [
  { id: LOCALES.ENGLISH, name: 'English', icon: 'flag-icon-us', code: 'en' },
  { id: LOCALES.JAPANESE, name: 'Japanese', icon: 'flag-icon-jp', code: 'ja' }
]
export const dateFormats = {
  [LOCALES.ENGLISH]: 'MM/dd/yyyy',
  [LOCALES.JAPANESE]: 'yyyy/MM/dd'
}
