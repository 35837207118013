const state = {
  exceedAlertThreshold: false
}

const getters = {}

const mutations = {
  setExceedAlertThreshold(state, item) {
    state.exceedAlertThreshold = item
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
